@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.about-service{
    height: 58rem;
}
.services{
    display: flex;
    flex-direction: column;
}

.serv-head{
    display: flex;
    flex-direction: column;
    margin-right: 34vw;
}

.serv-head>b{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: #ff451b;
}

.serv-head>h2{
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    color: black;
    width: 350px;
    margin-top: 0px;
}

.serv-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}




/* "ServiceData" */


.serviceData{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 18vw;
    border-radius: 10px;
    box-shadow: 0 5px 60px -4px rgb(0 0 0 / 0.25);
    text-align: center;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
}


.serviceData>span{
    width: 100px;
    height: 100px;
    background-color: orange;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

}


.serviceData>span>*{
    width: 40px;
    height: 40px;
    color: white;
}

.serviceData>p{
    color: black;
    opacity: 0.8;
    margin-top: -10px;
}


@media screen and (max-width:1100px) {
    .about-service{
        height: 80rem;
    }
    .serv-head{
        margin-left: 20px;

    }
    .serv-head>h2{
        width: 100%;
    }
    .serv-content{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        margin-left: 30px;
    }
    .serviceData{
        width: 40vw;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media screen and (max-width:550px) {
    .Services{
        margin-top: -40px;
    }
    .about-service{
        height: 153rem;
    }
    .serv-content{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 1rem;
    }
    .serviceData{
        width: 80vw;
    }
}