@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.modal{
    overflow-y: none;
}

/* width */
.modal>::-webkit-scrollbar {
    width: 5px;
    border-radius: 2px;
    height: 15px;
  }
  
  /* Track */
  .modal>::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .modal>::-webkit-scrollbar-thumb {
    background: #cfcfcf;
  }

.BlogPage{
    display: flex;
    flex-direction: column;
}

.BlogPage>img{
    width: 650px;
    height: 300px;
    border-radius: 20px;
}

.BlogPage>p:nth-of-type(1){
    font-family: 'Poppins', sans-serif;
    color: rgb(39, 39, 38);
    opacity: 0.8;
    margin-top: 30px;
}
.BlogPage>h3{
    font-family: 'Poppins', sans-serif;
    color: rgb(0, 0, 0);
    margin-top: -10px;
    font-size: 20px;
}

.blog-icon{
    width: 40px;
    height: 40px;
    background-color: transparent;
    color: white;
    border: 4px solid white;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 80vw;
    margin-top: 70px;
    cursor: pointer;
}