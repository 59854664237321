@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');


.heading{
    width: 70vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-top: 20px;
    cursor: pointer;
    padding: 20px;
    margin-bottom: 10px;
    gap: 0.3rem;
}

.heading>span{
    height: 120px;
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    overflow-x: hidden;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);

}

.heading>span>*{
    color: white;
    height: 40px;
    width: 40px;
}
.heading>span:nth-of-type(1){
    background-color: #ff451b;
}
.heading>span:nth-of-type(2){
    background-color: orange;
}
.heading>div{
    width: 65vw;
    height: 120px;
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
}

.heading>div>b{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
}

@media screen and (max-width:1100px) {
    .heading{
        width: 90vw;
        margin-top: -10px;
    }
    .heading>span:nth-of-type(2){
        display: none;
    }
    .heading>span{
        height: 60px;
        width: 60px;
    }
    .heading>span>*{
        color: white;
        height: 20px;
        width: 20px;
    }
    .heading>div{
        height: 60px;
        width: 80vw;
    }
}