.footer{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.footer>div:nth-of-type(1){
    font-family: 'Poppins', sans-serif;
    color: rgb(160, 158, 158);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer>div:nth-of-type(2){
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


@media screen and (max-width:480px) {

    .footer{
        display: flex;
        flex-direction: column;
    }
}
