.app{
    width: 100vw;
    overflow-x: hidden;

}

.about{
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    /* margin-bottom: 10px; */

}