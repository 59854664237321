@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');


.blog{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.blog-head{
    display: flex;
    flex-direction: column;
    margin-right: 34vw;
}

.blog-head>b{
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    color: #ff451b;
}

.blog-head>h2{
    font-family: 'Poppins', sans-serif;
    font-size: 35px;
    color: black;
    width: 350px;
    margin-top: 10px;
}

.blog-page{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}




.blog-page>.blog-sec{
    height: 20rem;
    width: 18vw;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    cursor: pointer;
    overflow: hidden;
}
.blog-page>.blog-sec>img{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    z-index: 1;
    height: 150px;
    width: 100%;
    transition: transform 0.2s ease-in-out;
    margin-top: -18px;
}
.blog-page > .blog-sec > img:hover {
    transform: scale(1.04);
  }
.blog-page>.blog-sec>b{
    margin-top: 15px;
    margin-left: 10px;
    color: #ff451b;
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
}
.blog-page>.blog-sec>h3{
    margin-top:10px;
    margin-left: 10px;
    font-family: 'Poppins', sans-serif;
}




@media screen and (max-width:1100px) {
    .about-blog{
        height: 62rem;
    }
    .blog-head{
        margin-left: 140px;
    }
    .blog{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
    }
    .blog-page{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    .blog-page>.blog-sec{
        width: 30vw;
    }
}

@media screen and (max-width:700px) {
    .blog-head{
        margin-left: 10px;
    }
    .blog-page>.blog-sec{
        width: 35vw;
    }
}

@media screen and (max-width:480px) {
    .about-blog{
        height: 80rem;
    }
    .blog-page{
        display: flex;
        flex-direction: column;
        margin-left: -120px;
    }
    .blog-page>.blog-sec{
        width: 65vw;
    }
}